<template>
  <v-menu
    v-model="menu"
    class="time-picker"
    :close-on-content-click="false"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    :nudge-right="40"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="computedTime"
        :solo="solo"
        :hint="hint"
        persistent-hint
        class="mb-2"
        :solo-inverted="soloInverted"
        :error-messages="errorMessages"
        :outlined="outline"
        :disabled="disabled"
        :hide-details="hideDetails"
        :label="label"
        readonly
        :single-line="singleLine"
        :prepend-icon="iconName"
        v-on="on"
      />
    </template>
    <v-time-picker v-model="computedTime" landscape format="24hr" locale="pt-BR" :max="max" :min="min" @change="input" />
  </v-menu>
</template>

<script>
export default {
  props: {
    label: String,
    time: String,
    singleLine: Boolean,
    icon: Boolean,
    max: String,
    min: String,
    outline: Boolean,
    hideDetails: Boolean,
    soloInverted: Boolean,
    solo: Boolean,
    hint: String,
    disabled: Boolean,
    errorMessages: [Array, String]
  },
  data: () => ({
    menu: false
  }),
  computed: {
    computedTime: {
      get() {
        return this.time
      },
      set(value) {
        this.$emit('update:time', value)
      }
    },
    iconName() {
      if (this.icon) return 'event'
      return ''
    }
  },
  methods: {
    input(value) {
      this.$emit('input', value)
      this.menu = false
    }
  }
}
</script>
<style lang="scss">
.time-picker {
  .v-text-field__details {
    margin: 0 !important;
  }
}
</style>
